<template>
    <el-form ref='formRef' :model='formModel' :rules='rules' inline :label-width='labelWidth' size='default'
             :label-position='labelPosition'
             :class="['search-form', showAll ? 'search-form-show' : 'search-form-hide']">
        <el-row :gutter='8'>
            <el-col v-for='item in realConfig' :xs='24' :sm='item.smSpan' :md='item.mdSpan' :lg='item.span'
                    :xl='item.span'>
                <el-form-item :label='item.label' :prop='item.prop' :label-width='getLabelWidth(item)'
                              :title='dataRangeTitle(item)'>
                    <template v-if="item.type === 'input'">
                        <new-input v-model='formModel[item.prop]' v-bind='item.bindProps'
                                   @keydown.enter='enterHandle(item)' />
                    </template>

                    <template v-if="item.type === 'select'">
                        <el-select v-model='formModel[item.prop]' v-bind='item.bindProps' @change='enterHandle(item)'
                                   clearable filterable>
                            <el-option v-for='v in item.options' :key='v[item.bindProps.valueKey]'
                                       :label='v[item.bindProps.labelKey]' :value='v[item.bindProps.valueKey]' />
                        </el-select>
                    </template>

                    <template v-if="item.type === 'isvSelect'">
                        <isvSelect v-model='formModel[item.prop]' v-bind='item.bindProps' @change='enterHandle(item)' />
                    </template>

                    <template v-if="item.type === 'cascader'">
                        <el-cascader v-model='formModel[item.prop]' v-bind='item.bindProps'
                                     @change='enterHandle(item)' />
                    </template>

                    <template v-if="item.type === 'date'">
                        <el-date-picker v-model='formModel[item.prop]' v-bind='item.bindProps'
                                        @change='enterHandle(item)' />
                    </template>

                    <template v-if="item.type === 'dateRange'">
                        <el-date-picker v-model='formModel[item.prop]' v-bind='item.bindProps'
                                        @change='enterHandle(item)' :clearable='false'/>
                    </template>
                    <template v-if="item.type === 'switch'">
                        <el-switch v-model='formModel[item.prop]' v-bind='item.bindProps'
                                        @change='enterHandle(item)' />
                    </template>
                    <template v-if="item.type === 'remote'">
                        <remoteSelect v-model='formModel[item.prop]' :config='item' @change='enterHandle(item)' />
                    </template>
                </el-form-item>
            </el-col>
            <el-col class='search-btns' v-if='showBtn' :xs='24' :sm='defaultSpan.smSpan' :md='defaultSpan.mdSpan'
                    :lg='defaultSpan.span' :xl='defaultSpan.span'>
                <el-form-item>
                    <el-button type='primary' @click='search'>
                        <el-icon>
                            <Search />
                        </el-icon>
                        查询
                    </el-button>
                    <el-button @click='reset'>
                        <el-icon>
                            <RefreshRight />
                        </el-icon>
                        重置
                    </el-button>
                    <el-button v-if='realConfig.length > min' @click='showAll = !showAll'>
                        <el-icon v-if='showAll'>
                            <ArrowUp />
                        </el-icon>
                        <el-icon v-else>
                            <ArrowDown />
                        </el-icon>
                        {{ showAll ? '收起' : '展开' }}
                    </el-button>
                </el-form-item>
            </el-col>
        </el-row>

        <!--        <div class="form-more" v-if="realConfig.length > 3">
            <span @click="showAll = !showAll">
                {{ showAll ? '收起更多' : '展开更多' }}
                <el-icon style="margin-left: 3px"><component :is="showAll ? 'ArrowUp' : 'ArrowDown'" /></el-icon>
            </span>
        </div>-->
    </el-form>
</template>

<script>
import { baseFields, defaultConfig, shortcuts } from './utils';
import newInput from '../input/newInput';
import isvSelect from '../isvSelect/isvSelect';
import remoteSelect from './remoteSelect';
import { useUserInfo } from '@/stores/userInfo';

const userStore = useUserInfo();

export default {
    name: 'searchForm',
    components: {
        newInput,
        isvSelect,
        remoteSelect,
    },
    props: {
        labelWidth: {
            type: String,
            default: '90px',
        },
        labelPosition: {
            type: String,
            default: 'right',
        },
        config: {
            type: Array,
            default: () => [],
        },
        rules: {
            type: Array,
            default: () => [],
        },
        modelValue: {
            type: Object,
            default: () => ({}),
        },
        showBtn: {
            // 是否显示查询、重置按钮，默认显示
            type: Boolean,
            default: true,
        },
        dict: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['reset', 'submit', 'update:modelValue', 'update:dict'],
    data({ modelValue, dict }) {
        return {
            formModel: { ...modelValue },
            realConfig: [],
            showAll: false,
            defaultSpan: {
                span: 4,
                mdSpan: 6,
                smSpan: 8,
            },
            dict: dict,
            min: 4,
        };
    },

    watch: {
        formModel: {
            handler(val) {
                this.$emit('update:modelValue', val);
            },
            deep: true,
        },
    },

    created() {
        this.init();
        this.initStyle();
        window.addEventListener('resize', this.initStyle);
    },

    unmounted() {
        window.removeEventListener('resize', this.initStyle);
    },

    methods: {
        initStyle() {
            this.$nextTick(() => {
                let hwidth = document.body.clientWidth;
                let arr = [
                    [0, 767],
                    [768, 991],
                    [992, 1199],
                    [1200, 1919],
                    [1920, 99999],
                ];
                let map = [1, 2, 3, 5];
                let index = arr.findIndex((el) => hwidth > el[0] && hwidth <= el[1]);
                this.min = map[index] || 5;
            });
        },
        init() {
            let config = this.config;
            let realConfig = [];
            config.forEach((item) => {
                let result = {};
                switch (item.type) {
                    case 'input':
                        result = this.getInputConfig(item);
                        break;
                    case 'select':
                    case 'isvSelect':
                    case 'remote':
                        result = this.getSelectConfig(item);
                        break;
                    case 'cascader':
                        result = this.getSelectConfig(item);
                        break;
                    case 'date':
                    case 'dateRange':
                        result = this.getDateConfig(item);
                        break;
                    case 'switch':
                        result = this.getSwitchConfig(item);
                        break;
                }
                if (item.show !== false) {
                    if (item.prop === 'isvNo') {
                        if (userStore.sysType === 0) {
                            realConfig.push(result);
                        }
                    } else if (item.prop === 'mchNo') {
                        if (userStore.sysType === 0 || userStore.sysType === 1) {
                            realConfig.push(result);
                        }
                    } else {
                        realConfig.push(result);
                    }
                }
            });

            this.realConfig = realConfig;
        },

        reset() {
            this.$refs.formRef.resetFields();
            this.$emit('reset');
        },

        search() {
            this.$refs.formRef.validate((valid) => {
                if (valid) {
                    this.$emit('submit');
                }
            });
        },

        // enter 触发搜索
        enterHandle(item) {
            if (item.autoSearch) {
                this.search();
            }
        },

        getLabelWidth(item) {
            if (!item.label) return '0px';
            if (item.bindProps.labelWidth) return item.bindProps.labelWidth;
            return this.labelWidth;
        },

        // 合并默认配置
        getBaseConfig(item) {
            let config = {
                ...defaultConfig[item.type],
                ...item,
            };

            let result = {
                bindProps: {
                    ...(item.bindProps || {}),
                },
            };

            delete config.bindProps;

            Object.keys(config).forEach((key) => {
                if (baseFields.includes(key)) {
                    result[key] = config[key];
                } else {
                    result.bindProps[key] = config[key];
                }
            });
            return result;
        },

        // 执行获取下拉数据源方法
        // fn 示例
        /*fn(){
		    return new Promise(resolve => {
                axios.get('/list').then(res=>{
                    if(res.code===200){
                        return resolve(res.data);
                    }
                })
            })
        },*/
        getRemoteData(result, fn) {
            fn().then((res) => {
                let index = this.realConfig.findIndex((el) => el.prop === result.prop);
                this.realConfig[index].options = res;
                this.dict[result.prop] = res;
                this.$emit('update:dict', this.dict);
            });
        },

        getInputConfig(item) {
            return this.getBaseConfig(item);
        },

        getSwitchConfig(item) {
            return this.getBaseConfig(item);
        },

        getSelectConfig(item) {
            let result = this.getBaseConfig(item);
            let data = result.data;
            result.options = [];
            if (typeof data === 'function') {
                this.getRemoteData(result, data);
            } else {
                result.options = data;
            }
            return result;
        },

         getDateConfig(item) {
            let result = this.getBaseConfig(item);
            if (result.bindProps.dateType) {
                result.bindProps.type = result.bindProps.dateType;
            }
            if (result.bindProps.shortcuts) {
                result.bindProps.shortcuts = shortcuts;
            }
            return result;
        },

        dataRangeTitle(item) {
            if (item.type === 'dateRange') {
                let val = this.formModel[item.prop];
                if (val?.length) {
                    return val.join('-');
                }
            }

            return '';
        },
    },
};
</script>

<style lang='scss' scoped>
.search-form {
    :deep(.el-form-item) {
        width: 100%;
        margin: 0 !important;

        .el-select,
        .el-cascader {
            width: 100%;
        }
    }

    .form-more {
        position: relative;
        margin-bottom: 30px;
        border-top: 1px solid #dcdfe6;

        span {
            position: absolute;
            width: 140px;
            left: 50%;
            top: -1px;
            font-size: 12px;
            height: 20px;
            text-align: center;
            margin-left: -70px;
            z-index: 999;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #dcdfe6;
            border-top-color: #ecf5ff;
            border-radius: 0 0 4px 4px;
            background: #fff;
            cursor: pointer;
            user-select: none;
        }
    }

    .search-btns {
        display: block !important;

        :deep(.el-form-item__content) {
            flex-wrap: nowrap;
        }
    }
}

@media only screen and (max-width: 768px) {
    .search-form.search-form-hide {
        :deep(.el-row) {
            .el-col {
                display: block;
            }

            .el-col:nth-child(n + 2) {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .search-form.search-form-hide {
        :deep(.el-row) {
            .el-col {
                display: block;
            }

            .el-col:nth-child(n + 3) {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .search-form.search-form-hide {
        :deep(.el-row) {
            .el-col {
                display: block;
            }

            .el-col:nth-child(n + 4) {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 1920px) {
    .search-form.search-form-hide {
        :deep(.el-row) {
            .el-col {
                display: block;
            }

            .el-col:nth-child(n + 6) {
                display: none;
            }
        }
    }
}
</style>
