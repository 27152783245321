import * as ElementPlusIconsVue from '@element-plus/icons-vue';

let iconNames = {};
for (const i in ElementPlusIconsVue) {
    iconNames[ElementPlusIconsVue[i].name] = true;
}

export const iconMap = iconNames;

export default {
    install(app) {
        for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
            app.component(key, component);
        }
    },
};
