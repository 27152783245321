export function isUndef(val) {
    return val === '' || val === undefined || val === null;
}

// 初始化columns
export function initColumns(columns = []) {
    return columns.map((item, index) => {
        return {
            ...item,
            _seq: 'c_' + index,
            fixed: item.fixed || '',
            freezz: item.freezz || false,
            show: true,
        };
    });
}

// 根据columnsStateMap获取新的columns
export function getRealColumns(columns, columnsStateMap = {}) {
    return columns.map((item) => {
        let m = columnsStateMap[item._seq] || {};
        return {
            ...item,
            ...m,
        };
    });
}

// 根据fixed 分组
export function getColumnsForType(columns, type = '') {
    let origin = columns.filter((item) => item.fixed === type);
    let result = [];
    let arr = [];
    origin.forEach((item) => {
        if (!isUndef(item._moveIndex)) {
            arr.push({ index: item._moveIndex, item });
        } else {
            result.push(item);
        }
    });
    if (arr.length) {
        arr = arr.sort((a, b) => a.index - b.index);
        arr.forEach((item) => {
            result.splice(item.index, 0, item.item);
        });
    }
    return result;
}

// 改变顺序后更新index
// 向上移动，原位置至新位置index++
// 向下移动，原位置至新位置index--
export function updateMapIndex(columnsStateMap, columns, index, oldIndex) {
    let isUp = index < oldIndex;
    columns.forEach((item) => {
        if (columnsStateMap[item._seq]) {
            let state = columnsStateMap[item._seq];
            if (!isUndef(state._moveIndex)) {
                if (isUp && state._moveIndex >= index && state._moveIndex <= oldIndex) {
                    state._moveIndex = state._moveIndex + 1;
                }

                if (!isUp && state._moveIndex >= oldIndex && state._moveIndex <= index) {
                    state._moveIndex = state._moveIndex - 1;
                }
            }
        }
    });
}

// 分组后把目标之后的index--
export function updateMapFixedIndex(columnsStateMap, columns, element) {
    let index = columns.findIndex((el) => el._seq === element._seq);
    columns.forEach((item) => {
        if (columnsStateMap[item._seq]) {
            let state = columnsStateMap[item._seq];
            if (!isUndef(state._moveIndex) && state._moveIndex > index) {
                state._moveIndex = state._moveIndex - 1;
            }
        }
    });
}

// 过滤Columns，去掉不需要的属性
export function filterColumns(columns) {
    let result = [];

    columns.forEach((el) => {
        if (el.show) {
            let obj = { ...el };
            delete obj.show;
            if (!obj.fixed) {
                delete obj.fixed;
            }
            result.push(obj);
        }
    });

    return result;
}

// 给单元格宽度赋值
export function setColumnWidth(oldColumns, newColumns) {
    newColumns.forEach((item) => {
        let target = oldColumns.find((el) => item._seq === el._seq);
        if (target) {
            item.width = target.width;
        }
    });
}

// 获取表格宽度
export function getTableWidth(columns) {
    return columns.reduce((a, b) => {
        let w = b.width || 100;
        return a + w;
    }, 0);
}
