import request from '@/utils/request';

/**
 * （不建议写成 request.post(xxx)，因为这样 post 时，无法 params 与 data 同时传参）
 *
 * 登录api接口集合
 * @method signIn 用户登录
 * @method signOut 用户退出登录
 */

export function signIn(data) {
	return request({
		url: '/auth/login',
		method: 'post',
		data,
	});
}

export function signOut(data) {
	return request({
		url: '/user/signOut',
		method: 'post',
		data,
	});
}

export function getUserInfo(data) {
	return request({
		url: '/currentUser',
		method: 'get',
		data,
	});
}
