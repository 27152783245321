/**
 * 处理使用async函数时try-catch的问题
 * */
export function asyncFunErr(promise) {
    return promise.then((data) => [null, data]).catch((err) => [err, null]);
}

/**
 * 获取数据类型
 * */
function getType(data) {
    return Object.prototype.toString.call(data).slice(8, -1);
}

/**
 *  深克隆
 * */
function isCyclic(data) {
    let seenObjects = [];

    function detect(data) {
        if (data && getType(data) === 'Object') {
            if (seenObjects.indexOf(data) !== -1) {
                return true;
            }
            seenObjects.push(data);
            for (let key in data) {
                if (data.hasOwnProperty(key) === true && detect(data[key])) {
                    return true;
                }
            }
        }
        return false;
    }

    return detect(data);
}

export function deepClone(data) {
    if (data === null) {
        return null;
    }
    if (data === undefined) {
        return undefined;
    }
    let dataType = getType(data);
    if (dataType === 'Date') {
        let dataDate = data;
        let clonedDate = new Date();
        clonedDate.setTime(dataDate.getTime());
        return clonedDate;
    }
    if (dataType === 'Object') {
        if (isCyclic(data) === true) {
            return data;
        }
        let copiedObject = {};
        for (let key in data) {
            copiedObject[key] = deepClone(data[key]);
        }
        return copiedObject;
    }
    if (dataType === 'Array') {
        let copiedArray = [];
        let dataArray = data;
        for (let i = 0; i < dataArray.length; i++) {
            copiedArray.push(deepClone(dataArray[i]));
        }
        return copiedArray;
    } else {
        return data;
    }
}

export function isUndef(val) {
    return val === null || val === undefined || val === '';
}

export const floatObj = (function () {
    /*
     * 判断obj是否为一个整数
     */
    function isInteger(obj) {
        return Math.floor(obj) === obj;
    }

    /*
     * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
     * @param floatNum {number} 小数
     * @return {object}
     *   {times:100, num: 314}
     */
    function toInteger(floatNum) {
        var ret = { times: 1, num: 0 };
        if (isInteger(floatNum)) {
            ret.num = floatNum;
            return ret;
        }
        var strfi = floatNum + '';
        var dotPos = strfi.indexOf('.');
        var len = strfi.substr(dotPos + 1).length;
        var times = Math.pow(10, len);
        var intNum = parseInt(floatNum * times + 0.5, 10);
        ret.times = times;
        ret.num = intNum;
        return ret;
    }

    /*
     * 核心方法，实现加减乘除运算，确保不丢失精度
     * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
     *
     * @param a {number} 运算数1
     * @param b {number} 运算数2
     * @param op {string} 运算类型，有加减乘除（add/subtract/multiply/divide）
     *
     */
    function operation(a, b, op) {
        var o1 = toInteger(a);
        var o2 = toInteger(b);
        var n1 = o1.num;
        var n2 = o2.num;
        var t1 = o1.times;
        var t2 = o2.times;
        var max = t1 > t2 ? t1 : t2;
        var result = null;
        switch (op) {
            case 'add':
                if (t1 === t2) {
                    // 两个小数位数相同
                    result = n1 + n2;
                } else if (t1 > t2) {
                    // o1 小数位 大于 o2
                    result = n1 + n2 * (t1 / t2);
                } else {
                    // o1 小数位 小于 o2
                    result = n1 * (t2 / t1) + n2;
                }
                return result / max;
            case 'subtract':
                if (t1 === t2) {
                    result = n1 - n2;
                } else if (t1 > t2) {
                    result = n1 - n2 * (t1 / t2);
                } else {
                    result = n1 * (t2 / t1) - n2;
                }
                return result / max;
            case 'multiply':
                result = (n1 * n2) / (t1 * t2);
                return result;
            case 'divide':
                result = (n1 / n2) * (t2 / t1);
                return result;
        }
    }

    // 加减乘除的四个接口
    function add(a, b) {
        return operation(a, b, 'add');
    }

    function subtract(a, b) {
        return operation(a, b, 'subtract');
    }

    function multiply(a, b) {
        return operation(a, b, 'multiply');
    }

    function divide(a, b) {
        return operation(a, b, 'divide');
    }

    // exports
    return {
        add: add,
        subtract: subtract,
        multiply: multiply,
        divide: divide,
    };
})();

export function isType(type) {
    return function (obj) {
        return Object.prototype.toString.call(obj) === `[object ${type}]`;
    };
}

export const isArray = isType('Array');
export const isObject = isType('Object');

// 字符串是否可转成object
export function strIsObject(str) {
    if (isUndef(str)) return false;

    try {
        let obj = JSON.parse(str);
        if (isArray(obj) || isObject(obj)) {
            return true;
        }
    } catch (e) {}
    return false;
}

// 对象是否为空
export function isEmpty(obj) {
    if (isUndef(obj)) {
        return true;
    }
    if (isArray(obj) && obj.length === 0) {
        return true;
    }
    if (isObject(obj) && Object.keys(obj).length === 0) {
        return true;
    }

    return false;
}

const type = (val) => {
    return Object.prototype.toString.call(val);
};

// 对比两个对象是否一致
export function diffObj(o1 = {}, o2 = {}) {
    if (type(o1) !== type(o2)) {
        return false;
    }
    let o1Keys = Object.keys(o1);
    let o2Keys = Object.keys(o2);

    if (o1Keys.length !== o2Keys.length) {
        return false;
    }
    for (let i = 0; i < o1Keys.length; i++) {
        let val1 = o1[o1Keys[i]];
        let val2 = o2[o1Keys[i]];
        if (typeof val1 == 'object' || typeof val2 == 'object') {
            let result = diffObj(val1, val2);
            if (!result) return result;
        } else if (o1[o1Keys[i]] != o2[o1Keys[i]]) {
            return false;
        }
    }
    return true;
}

// 是否是绝对路径的url
export function isAbsoluteURL(url) {
    return /^([a-z][a-z\d\+\-\.]*:)?\/\//i.test(url);
}

// 将baseUrl和相对路径拼接
export function combineURLs(baseURL = '', relativeURL = '') {
    return relativeURL ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '') : baseURL;
}

// 初始化字段结果
export function initDictKey(dictKeys) {
    let result = {};
    dictKeys.forEach((key) => {
        result[key] = [];
    });
    return result;
}

// 将target上的属性赋值到current
export function mergObject(current = {}, target = {}) {
    Object.keys(current).forEach((k) => {
        let item = current[k];
        let t = target[k];
        if (isObject(item) && isObject(t)) {
            mergObject(item, t);
            return;
        }
        if (!isUndef(t)) {
            current[k] = t;
        }
    });
}
