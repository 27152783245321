<template>
    <el-select :model-value="modelValue" v-bind="config.bindProps" @change="changeHandle" filterable remote reserve-keyword remote-show-suffix :remote-method="remoteMethod" :loading="loading">
        <el-option v-for="item in options" :key="item[config.bindProps.valueKey]" :label="item[config.bindProps.labelKey]" :value="item[config.bindProps.valueKey]" />
    </el-select>
</template>

<script>
import { ElNotification } from 'element-plus';

export default {
    name: 'remoteSelect',
    props: {
        modelValue: undefined,
        config: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            options: [],
            loading: false,
        };
    },
    methods: {
        // 处理远程请求
        remoteMethod(query) {
            if (query) {
                this.loading = true;
                let fn = this.config.request;
                let queryKey = this.config.queryKey;
                let payload = {};
                payload[queryKey] = query;
                fn(payload)
                    .then((res) => {
                        if (res.code === 200) {
                            this.options = res.data.records || [];
                            console.info(this.options)
                        } else {
                            ElNotification.error(res.msg);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.options = [];
            }
        },

        changeHandle(e) {
            this.$emit('update:modelValue', e);
            this.$emit('change', e);
        },
    },
};
</script>

<style scoped></style>
