<template>
    <div class="pro-table">
        <TableTop :title="title" :columns="_columns" :tableSize="tableSize" :columnsState="realColumnsStateMap" @columnChange="columnChange" @sizeChange="sizeChange" @reload="reload" @export="exportHandle">
            <template #default>
                <slot name="headerOps"></slot>
            </template>

            <template #right>
                <slot name="headerRight"></slot>
            </template>
        </TableTop>

        <slot name="tableTop"></slot>

        <el-table :data="data" :key="tableKey" :size="tableSize" ref="table" v-bind="this.getTableProps()" :tree-props="treeProps">
            <template v-for="(child, index) in children" :key="index">
                <component :is="child"></component>
            </template>
        </el-table>

        <!--  导出excel的table -->
        <el-table class="pro-table-excel" :data="data" :key="tableKey" :size="tableSize" ref="exportTable" v-bind="this.getTableProps()" :tree-props="treeProps">
            <template v-for="(child, index) in excelChildren" :key="index">
                <component :is="child"></component>
            </template>
        </el-table>
    </div>
</template>

<script>
import { cloneVNode } from 'vue';
import TableTop from './TableTop';
import { setColumnWidth } from './utils';
import { exportExcel } from './exportExcel';
import './proTable.scss';
import { useRoute } from 'vue-router';
const route = useRoute();

export default {
    name: 'pro-table',
    components: {
        TableTop,
    },
    props: {
        // 表格数据源
        data: {
            type: Array,
            default: () => [],
        },
        treeProps: {
            type: Object,
            default: () => ({ children: 'children', hasChildren: 'hasChildren' }),
        },
        // 表格列配置信息
        columns: {
            type: Array,
            default: () => [],
        },
        // 左上角显示的title
        title: {
            type: String,
        },
        // 占位字符
        emptyTxt: {
            type: String,
            default: '------',
        },
        // 是否使用占位字符
        isShowEmptyTxt: {
            type: Boolean,
            default: true,
        },

        columnsStateMap: {
            type: Object,
        },

        // 本地存储的key值，每个表格需唯一
        storageKeyName: {
            type: String,
        },
    },

    data() {
        return {
            _columns: [],
            realColumns: [],
            tableSize: 'large',
            localColumnsStateMap: null,
            tableKey: 'default',
        };
    },

    computed: {
        realColumnsStateMap() {
            return this.storageKeyName && !this.columnsStateMap ? this.localColumnsStateMap : this.columnsStateMap;
        },

        children() {
            return this.realColumns.map((item) => this.renderTableItem(item));
        },

        excelChildren() {
            return this.realColumns.filter((el) => el.title && el.title !== '操作').map((item) => this.renderTableItem(item));
        },
    },

    created() {
        this.init();
        console.info(this.treeProps);
    },

    methods: {
        init() {
            let children = this.$slots.default();
            if (!this.columns.length && children?.length) {
                let _columns = [];
                children.forEach((vnode) => {
                    if (vnode.type?.name === 'ElTableColumn') {
                        let options = vnode.props;
                        _columns.push({
                            title: options.label,
                            field: options.prop,
                            width: options.width,
                            fixed: options.fixed,
                            freezz: options.freezz,
                            vnode,
                        });
                    }
                });
                this._columns = _columns;
            } else {
                this._columns = this.columns;
            }
            // 内部处理columnsStateMap本地存储
            if (this.storageKeyName && !this.columnsStateMap) {
                let localColumnsState = localStorage.getItem(this.storageKeyName);
                this.localColumnsStateMap = localColumnsState ? JSON.parse(localColumnsState) : {};
            }
        },

        columnChange(newColumns, columnsStateMap) {
            setColumnWidth(this.realColumns, newColumns);
            this.$nextTick(() => {
                this.realColumns = [...newColumns];
                this.tableKey = 'table_' + Date.now();
                this.$emit('onColumnsStateMap', columnsStateMap); // 可监听该事件，把columnsStateMap存储在storage中做持久化

                // 增加本地持久化处理
                if (this.storageKeyName && !this.columnsStateMap) {
                    localStorage.setItem(this.storageKeyName, JSON.stringify(columnsStateMap));
                }
            });
        },

        getTableProps() {
            let $attrs = this.$attrs || {};
            const boolProps = ['stripe', 'border', 'fit', 'show-header', 'highlight-current-row', 'default-expand-all', 'show-summary', 'select-on-indeterminate'];

            Object.keys($attrs).forEach((field) => {
                if (boolProps.includes(field) && $attrs[field] === '') {
                    $attrs[field] = true;
                }
            });

            return $attrs;
        },

        // 渲染el-table-column
        renderTableItem(item) {
            if (item.vnode) {
                return cloneVNode(item.vnode, {
                    fixed: item.fixed,
                });
            }

            return null;
        },

        // 切换密度
        sizeChange(e) {
            this.tableSize = e;
        },

        reload() {
            this.$emit('reload');
        },

        getTableRef() {
            return this.$refs.table;
        },

        exportHandle() {
            let head = this.realColumns.map((el) => el.title);
            head = head.filter((el) => el && el !== '操作');
            // 导出名用的this.title，如果没有就会是空的
            this.$nextTick(() => {
                exportExcel(this.$refs.exportTable.$el, head, this.$route.meta.title, () => {
                    console.log('导出完成');
                });
            });
        },
    },
};
</script>
