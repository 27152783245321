<template>
    <div class="table-top">
        <div class="title"><slot></slot></div>

        <div class="table-ops">
            <div class="table-btn">
                <slot name="right"></slot>
            </div>

            <a class="ops-icon">
                <el-tooltip effect="dark" content="刷新" placement="top">
                    <div @click="$emit('reload')">
                        <reload />
                    </div>
                </el-tooltip>
            </a>

            <a class="ops-icon">
                <el-tooltip effect="dark" content="导出当前页" placement="top">
                    <div @click="$emit('export')">
                        <exportIcon />
                    </div>
                </el-tooltip>
            </a>

            <a class="ops-icon">
                <el-tooltip effect="dark" content="密度" placement="top">
                    <el-dropdown @command="changeSize" trigger="click">
                        <span class="el-dropdown-link">
                            <columHeight />
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item :class="tableSize === 'large' ? 'active' : ''" command="large">宽松</el-dropdown-item>
                                <el-dropdown-item :class="tableSize === 'default' ? 'active' : ''" command="default">默认</el-dropdown-item>
                                <el-dropdown-item :class="tableSize === 'small' ? 'active' : ''" command="small">紧凑</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </el-tooltip>
            </a>

            <a class="ops-icon">
                <el-tooltip effect="dark" content="列设置" placement="top">
                    <div slot="reference" @click="showSetting = true">
                        <setting />
                    </div>
                </el-tooltip>
            </a>
        </div>

        <el-drawer v-model="showSetting" title="列设置" size="300px" direction="rtl">
            <div class="setting-pop-content">
                <div class="setting-header">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">列展示</el-checkbox>

                    <el-button type="text" @click="resetColumnsStateMap">重置</el-button>
                </div>

                <div class="setting-content">
                    <template v-if="fixeLeftColumns.length">
                        <p class="tip">固定在左侧</p>
                        <columnsList :list="fixeLeftColumns" type="left" @columnsChange="columnsChange($event, 'fixeLeftColumns')" />
                    </template>

                    <template v-if="defaultColumns.length">
                        <p class="tip">不固定</p>
                        <columnsList :list="defaultColumns" type="default" @columnsChange="columnsChange($event, 'defaultColumns')" />
                    </template>

                    <template v-if="fixeRightColumns.length">
                        <p class="tip">固定在右侧</p>
                        <columnsList :list="fixeRightColumns" type="right" @columnsChange="columnsChange($event, 'fixeRightColumns')" />
                    </template>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import reload from './icons/reload';
import columHeight from './icons/colum-height';
import setting from './icons/setting';
import compress from './icons/compress';
import expend from './icons/expend';
import exportIcon from './icons/export';
import columnsList from './columnsList';
import { initColumns, getRealColumns, getColumnsForType, updateMapIndex, updateMapFixedIndex, filterColumns } from './utils';

export default {
    name: 'TableTop',
    props: ['title', 'columns', 'columnsState', 'tableSize'],
    components: {
        reload,
        columHeight,
        setting,
        compress,
        expend,
        exportIcon,
        columnsList,
    },
    data({ columnsState }) {
        return {
            checkAll: true,
            realColumns: [],
            columnsStateMap: columnsState || {},
            showSetting: false,
        };
    },
    computed: {
        originColumns() {
            return initColumns(this.columns);
        },
        isIndeterminate() {
            let checkLen = this.realColumns.filter((el) => el.show).length;
            if (checkLen > 0 && checkLen < this.realColumns.length) {
                return true;
            }
            return false;
        },

        fixeLeftColumns() {
            return getColumnsForType(this.realColumns, 'left');
        },
        fixeRightColumns() {
            return getColumnsForType(this.realColumns, 'right');
        },
        defaultColumns() {
            return getColumnsForType(this.realColumns);
        },
    },

    created() {
        this.createNewColumns();
    },
    methods: {
        // 切换表格密度
        changeSize(e) {
            this.$emit('sizeChange', e);
        },

        // checkAll
        checkAllHandle(newColumns) {
            if (newColumns.length === 0) {
                this.checkAll = false;
            }

            if (newColumns.length === this.originColumns.length) {
                this.checkAll = true;
            }
        },

        //生成新的columns
        createNewColumns() {
            this.realColumns = getRealColumns(this.originColumns, this.columnsStateMap);
            this.$nextTick(() => {
                let newColumns = [...filterColumns(this.fixeLeftColumns), ...filterColumns(this.defaultColumns), ...filterColumns(this.fixeRightColumns)];
                this.checkAllHandle(newColumns);
                this.$emit('columnChange', newColumns, this.columnsStateMap);
            });
        },

        // 全选
        handleCheckAllChange() {
            this.originColumns.forEach((el) => {
                this.columnsStateMap[el._seq] = this.columnsStateMap[el._seq] || {};
                if (!el.freezz) {
                    this.columnsStateMap[el._seq].show = this.checkAll;
                }
            });
            this.createNewColumns();
        },

        // 重置按钮
        resetColumnsStateMap() {
            this.columnsStateMap = {};
            this.createNewColumns();
        },

        // 列表设置变动
        columnsChange(e, type) {
            let oldState, element;
            let target = type === 'fixeLeftColumns' ? this.fixeLeftColumns : type === 'fixeRightColumns' ? this.fixeRightColumns : this.defaultColumns;
            switch (e.type) {
                case 'index':
                    element = e.value.moved.element;
                    let { newIndex, oldIndex } = e.value.moved;
                    oldState = this.columnsStateMap[element._seq] || {};
                    updateMapIndex(this.columnsStateMap, target, newIndex, oldIndex);
                    this.columnsStateMap[element._seq] = { ...oldState, _moveIndex: newIndex };
                    break;
                case 'fixed':
                    element = e.value.element;
                    let fixed = e.value.fixed;
                    oldState = this.columnsStateMap[element._seq] || {};
                    updateMapFixedIndex(this.columnsStateMap, target, element);
                    let newObj = { ...oldState, fixed: fixed };
                    delete newObj._moveIndex;
                    this.columnsStateMap[element._seq] = newObj;
                    break;
                case 'show':
                    element = e.value.element;
                    let show = e.value.show;
                    oldState = this.columnsStateMap[element._seq] || {};
                    this.columnsStateMap[element._seq] = { ...oldState, show: show };
                    break;
            }
            this.createNewColumns();
        },
    },
};
</script>
