import pinia from '@/stores/index';
import { Session } from '@/utils/storage';
import { NextLoading } from '@/utils/loading';
import { dynamicRoutes, notFoundAndNoPower } from '@/router/route';
import { formatTwoStageRoutes, formatFlatteningRoutes, router } from '@/router/index';
import { useRoutesList } from '@/stores/routesList';
import { useTagsViewRoutes } from '@/stores/tagsViewRoutes';

/**
 * 后端控制路由：初始化方法，防止刷新时路由丢失
 * @method NextLoading 界面 loading 动画开始执行
 * @method useUserInfo().setUserInfos() 触发初始化用户信息 pinia
 * @method useRequestOldRoutes().setRequestOldRoutes() 存储接口原始路由（未处理component），根据需求选择使用
 * @method setAddRoute 添加动态路由
 * @method setFilterMenuAndCacheTagsViewRoutes 设置路由到 pinia routesList 中（已处理成多级嵌套路由）及缓存多级嵌套数组处理后的一维数组
 */
export async function initBackEndControlRoutes(routes) {
    // 界面 loading 动画开始执行
    if (window.nextLoading === undefined) NextLoading.start();

    dynamicRoutes[0].children = dynamicRoutes[0].children.concat(routes);
    // 添加动态路由
    await setAddRoute();
    // 设置路由到 pinia routesList 中（已处理成多级嵌套路由）及缓存多级嵌套数组处理后的一维数组
    // await setFilterMenuAndCacheTagsViewRoutes();
}

/**
 * 设置路由到 pinia routesList 中（已处理成多级嵌套路由）及缓存多级嵌套数组处理后的一维数组
 * @description 用于左侧菜单、横向菜单的显示
 * @description 用于 tagsView、菜单搜索中：未过滤隐藏的(isHide)
 */
export async function setFilterMenuAndCacheTagsViewRoutes() {
    const storesRoutesList = useRoutesList(pinia);
    storesRoutesList.setRoutesList(dynamicRoutes[0].children);
    setCacheTagsViewRoutes();
}

/**
 * 缓存多级嵌套数组处理后的一维数组
 * @description 用于 tagsView、菜单搜索中：未过滤隐藏的(isHide)
 */
export function setCacheTagsViewRoutes() {
    const storesTagsView = useTagsViewRoutes(pinia);
    storesTagsView.setTagsViewRoutes(formatTwoStageRoutes(formatFlatteningRoutes(dynamicRoutes))[0].children);
}

/**
 * 处理路由格式及添加捕获所有路由或 404 Not found 路由
 * @description 替换 dynamicRoutes（/@/router/route）第一个顶级 children 的路由
 * @returns 返回替换后的路由数组
 */
export function setFilterRouteEnd() {
    let filterRouteEnd = formatTwoStageRoutes(formatFlatteningRoutes(dynamicRoutes));
    // notFoundAndNoPower 防止 404、401 不在 layout 布局中，不设置的话，404、401 界面将全屏显示
    // 关联问题 No match found for location with path 'xxx'
    // filterRouteEnd[0].children = [...filterRouteEnd[0].children, ...notFoundAndNoPower];
    filterRouteEnd[0].children = [...dynamicRoutes[0].children, ...notFoundAndNoPower];
    return filterRouteEnd;
}

/**
 * 添加动态路由
 * @method router.addRoute
 * @description 此处循环为 dynamicRoutes（/@/router/route）第一个顶级 children 的路由一维数组，非多级嵌套
 * @link 参考：https://next.router.vuejs.org/zh/api/#addroute
 */
export async function setAddRoute() {
    await setFilterRouteEnd().forEach((route) => {
        router.addRoute(route);
    });
    NextLoading.done();
    setTimeout(() => {
        let url = location.hash.replace('#', '');
        router.replace(url.includes('login') ? '/home' : url);
    });
}

const routeMap = {
    '/mch/apply/list': {
        path: '/mch',
        name: '首页',
        component: () => import('@/layout/routerView/parent'),
        meta: {
            isKeepAlive: true,
            title: '首页',
        },
        children: [
            {
                path: '/mch/apply/list',
                name: '进件',
                component: () => import('@/views/mch/mchApply/open/index.vue'),
                meta: {
                    title: '进件',
                    isLink: '',
                    isHide: false,
                    isKeepAlive: true,
                    isAffix: true,
                    isIframe: false,
                },
            },
        ],
    },
};

/**
 * 动态添加路由
 * routeName
 * */
export async function setRoutes(routeName) {
    let current = routeMap[routeName];
    if (!current) return;
    if (dynamicRoutes[0].children.find((el) => el.path === current.path)) {
        return;
    }
    dynamicRoutes[0].children = dynamicRoutes[0].children.concat([current]);
    await setAddRoute();
}
