<template>
    <svg t="1650870777371" class="icon" viewBox="0 0 1024 1024" version="1.1"
         xmlns="http://www.w3.org/2000/svg" p-id="8548" width="20" height="20">
        <path d="M840 836H184c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h656c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8zM840 112H184c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h656c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8zM610.8 378c6 0 9.4-7 5.7-11.7L515.7 238.7c-2.9-3.7-8.5-3.7-11.3 0L403.6 366.3c-3.7 4.7-0.4 11.7 5.7 11.7H476v268h-62.8c-6 0-9.4 7-5.7 11.7l100.8 127.5c2.9 3.7 8.5 3.7 11.3 0l100.8-127.5c3.7-4.7 0.4-11.7-5.7-11.7H548V378h62.8z"
              p-id="8549" fill="#333"></path>
    </svg>
</template>

<script>
    export default {
        name: "colum-height"
    }
</script>

<style scoped>

</style>
