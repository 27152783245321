import { dayjs } from 'element-plus';

export const defaultConfig = {
    input: {
        span: 4,
        mdSpan: 6,
        smSpan: 8,
        placeholder: '请输入',
        clearable: true,
        autoSearch: true,
    },
    select: {
        span: 4,
        mdSpan: 6,
        smSpan: 8,
        placeholder: '请选择',
        clearable: true,
        labelKey: 'name',
        valueKey: 'value',
        autoSearch: false, // 该值为true，开启change后查询
    },
    isvSelect: {
        span: 4,
        mdSpan: 6,
        smSpan: 8,
        placeholder: '请选择',
        clearable: true,
        labelKey: 'name',
        valueKey: 'value',
        autoSearch: false, // 该值为true，开启change后查询
    },
    cascader: {
        span: 4,
        mdSpan: 6,
        smSpan: 8,
        placeholder: '请选择',
        clearable: true,
        autoSearch: true,
    },
    date: {
        span: 4,
        mdSpan: 6,
        smSpan: 8,
        placeholder: '选择时间',
        clearable: true,
        autoSearch: true,
    },
    dateRange: {
        span: 8,
        mdSpan: 6,
        smSpan: 8,
        autoSearch: true,
        dateType: 'daterange',
        'start-placeholder': '开始时间',
        'end-placeholder': '结束时间',
        'value-format': 'YYYY-MM-DD HH:mm:ss',
        'default-time': [dayjs().startOf('day'), dayjs().endOf('day')],
        shortcuts: true,
    },
    remote: {
        span: 4,
        mdSpan: 6,
        smSpan: 8,
        placeholder: '请选择',
        clearable: true,
        labelKey: 'name',
        valueKey: 'value',
        request: () => [],
        autoSearch: false, // 该值为true，开启change后查询
    },
};

export const baseFields = ['span', 'label', 'prop', 'type', 'data', 'mdSpan', 'smSpan', 'autoSearch', 'request', 'queryKey'];

export const shortcuts = [
    {
        text: '今天',
        value: [dayjs().startOf('day'), dayjs().endOf('day')],
    },
    {
        text: '昨天',
        value: () => {
            const start = dayjs().subtract(1, 'day');
            return [start.startOf('day'), start.endOf('day')];
        },
    },
    {
        text: '最近7天',
        value: () => {
            const start = dayjs().subtract(7, 'day');
            const end = dayjs();
            return [start.startOf('day'), end.endOf('day')];
        },
    },
    {
        text: '最近30天',
        value: () => {
            const start = dayjs().subtract(30, 'day');
            const end = dayjs();
            return [start.startOf('day'), end.endOf('day')];
        },
    },
];
