import { defineStore } from 'pinia';
import store from './index';
import { Session } from '@/utils/storage';
import { getUserInfo } from '@/api/login';
import { isEmpty, deepClone, isUndef } from '@/utils/lib';
import { defineAsyncComponent } from 'vue';
import { initBackEndControlRoutes } from '@/router/backEnd';
import { useTagsViewRoutes } from './tagsViewRoutes';
import { useKeepALiveNamesWithOut } from './keepAliveNames';

// layout: layout.index
// secondLayout: layout.routerView.parent
function getComponents(path) {
    if (!path?.length) return '';
    let arr = path.split('.');
    return defineAsyncComponent(() => import(`@/${arr.join('/')}`));
}

function getComponentName(component) {
  console.log(component)
}

/**
 * 用户信息
 * @methods setUserInfos 设置用户信息
 */
export const useUserInfo = defineStore('userInfo', {
    state: () => ({
        userInfos: {},
        menuList: [],
        btnAuth: {},
    }),

    getters: {
        btnPermission: (state) => {
            let { codeList = [] } = state.userInfos?.ext || {};
            return codeList;
        },

        sysType: (state) => {
            let sysType = state.userInfos.sysType || {};
            return sysType.value;
        },

        isMgr: (state) => {
            let sysType = state.userInfos.sysType || {};
            return sysType.value === 0;
        },

        isSrp: (state) => {
            let sysType = state.userInfos.sysType || {};
            return sysType.value === 1;
        },

        isMch: (state) => {
            let sysType = state.userInfos.sysType || {};
            return sysType.value === 2;
        },
    },

    actions: {
        async setUserInfos() {
            if (!isEmpty(this.userInfos)) {
                return;
            }
            // 存储用户信息到浏览器缓存
            let u = Session.get('userInfo');
            if (u) {
                this.userInfos = u;
            } else {
                await this.getApiUserInfo();
            }
            await this.getMenu();
        },
        // 模拟接口数据
        // https://gitee.com/lyt-top/vue-next-admin/issues/I5F1HP
        async getApiUserInfo() {
            return getUserInfo().then((res) => {
                if (res.code === 200) {
                    let info = res.data;
                    this.userInfos = info;
                    Session.set('userInfo', info);
                    return info;
                }
            });
        },

        getMenu() {
            return new Promise(async (resolve) => {
                let { menuList = [], codeList = [] } = this.userInfos?.ext || {};
                let names  =[];
                const codeMap = {};
                codeList.forEach((el) => {
                    codeMap[el] = true;
                });

                let btnAuth = {};
                let tagsViewRoutes = [];

                function getMenu(menu, urls = []) {
                    let list = [];
                    if (menu === null || menu === undefined) {
                        return list;
                    }
                    menu.forEach((el) => {
                        if(el.name !== '外部进件权限'){
                            if (codeMap[el.code]) {
                                let obj = {
                                    ...el,
                                    meta: {
                                        title: el.name,
                                        code: el.code,
                                        icon: el.icon,
                                        type: el.type,
                                        isShow: isUndef(el.isShow) ? true : el.isShow,
                                        isKeepAlive: isUndef(el.isKeepAlive) ? true : el.isKeepAlive,
                                        isAffix: isUndef(el.isAffix) ? false : el.isAffix,
                                        isIframe: isUndef(el.isIframe) ? false : el.isIframe,
                                    },
                                };
                                ['name', 'code', 'icon', 'type'].forEach((key) => {
                                    delete obj[key];
                                });
                                if (el.type?.value === 0) {
                                    let u = deepClone(urls).concat(el.uri);
                                    obj.path = u.join('');
                                    obj.component = getComponents(obj.componentName);
                                    names.push(obj.componentName);
                                    delete obj.children;
                                    tagsViewRoutes.push(obj);
                                    if (el.children?.length) {
                                        let result = getMenu(el.children, u);
                                        if (result.length) {
                                            obj.children = result;
                                        }
                                    }

                                    list.push(obj);
                                } else {
                                    let url = urls.join('');
                                    btnAuth[url] = (btnAuth[url] || []).concat(obj);
                                }
                            }
                        }
                    });

                    return list;
                }

                this.menuList = getMenu(menuList);
                this.btnAuth = btnAuth;
                useKeepALiveNamesWithOut().setCacheKeepAlive(names);
                await initBackEndControlRoutes([...this.menuList]);
                useTagsViewRoutes().setTagsViewRoutes(tagsViewRoutes);
                return resolve(1);
            });
        },

        reset() {
            this.userInfos = {};
            Session.remove('userInfo');
        },
    },
});

// 在组件外访问store调用
export function useUserInfoWithOut() {
    return useUserInfo(store);
}
