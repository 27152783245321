import hevueImgPreview from 'hevue-img-preview';
import searchForm from '@/components/searchForm';
import pagination from '@/components/pagination';
import proTable from '@/components/proTable';

export default {
    install(app) {
        app.component('searchForm', searchForm);
        app.component('pagination', pagination);
        app.component('proTable', proTable);
        app.use(hevueImgPreview, { clickMaskCLose: true });
    },
};
