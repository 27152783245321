import request from '@/utils/request';

const base = 'isvInfo';

export const auth = {
    add: 'ISV_INFO:ADD',
    del: 'ISV_INFO:DEL',
    edit: 'ISV_INFO:UPDATE',
    getProfit: 'ISV_SETTING:PROFIT:GET',
    setProfit: 'ISV_SETTING:PROFIT:SET',
    getBankInfo: 'ISV_SETTING:BANKINFO:GET',
    setBankInfo: 'ISV_SETTING:BANKINFO:SET',
    getPaymentSetUp: 'ISV_SETTING:PAYMENTSETUP:GET',
    getDevSetUp: 'ISV_SETTING:DEVSETUP:GET',
    setPaymentSetUp: 'ISV_SETTING:PAYMENTSETUP:SET',
};

export function reqList(data) {
    return request({
        url: `/${base}/list`,
        method: 'get',
        params: data,
    });
}
export function reqListAll(data) {
    return request({
        url: `/${base}/listAll`,
        method: 'get',
        params: data,
    });
}

export function reqAdd(data) {
    return request({
        url: `/${base}/add`,
        method: 'post',
        data,
    });
}

export function reqUpdate(data) {
    return request({
        url: `/${base}/update`,
        method: 'post',
        data,
    });
}

export function reqDelete(data) {
    return request({
        url: `/${base}/delById`,
        method: 'post',
        data,
    });
}
