<template>
    <VueDraggableNext class="check-group" :list="list" @change="columnsChangeIndex">
        <div class="check-item" v-for="item in list" :key="item._seq" :title="item.title">
            <svg class="move" viewBox="64 64 896 896" focusable="false" data-icon="holder" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                <path
                    d="M300 276.5a56 56 0 1056-97 56 56 0 00-56 97zm0 284a56 56 0 1056-97 56 56 0 00-56 97zM640 228a56 56 0 10112 0 56 56 0 00-112 0zm0 284a56 56 0 10112 0 56 56 0 00-112 0zM300 844.5a56 56 0 1056-97 56 56 0 00-56 97zM640 796a56 56 0 10112 0 56 56 0 00-112 0z"
                ></path>
            </svg>

            <el-checkbox v-model="item.show" :disabled="item.freezz" @change="columnsShowChange($event, item)">{{ item.title }}</el-checkbox>

            <el-tooltip effect="dark" content="固定左侧" placement="top" v-if="type === 'default' || type === 'right'">
                <a class="ops" @click="columnsFixedChange(item, 'left')">
                    <VerticalAlignTop />
                </a>
            </el-tooltip>

            <el-tooltip effect="dark" content="不固定" placement="top" v-if="type === 'left' || type === 'right'">
                <a class="ops" @click="columnsFixedChange(item, '')">
                    <VerticalAlignMiddl />
                </a>
            </el-tooltip>

            <el-tooltip effect="dark" content="固定右侧" placement="top" v-if="type === 'default' || type === 'left'">
                <a class="ops" @click="columnsFixedChange(item, 'right')">
                    <VerticalAlignBotto />
                </a>
            </el-tooltip>
        </div>
    </VueDraggableNext>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import VerticalAlignBotto from './icons/vertical-align-botto';
import VerticalAlignMiddl from './icons/vertical-align-middl';
import VerticalAlignTop from './icons/vertical-align-top';

export default {
    name: 'columnsList',
    components: { VueDraggableNext, VerticalAlignBotto, VerticalAlignMiddl, VerticalAlignTop },
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
        },
    },
    methods: {
        columnsChangeIndex(e) {
            this.$emit('columnsChange', { type: 'index', value: e });
        },
        columnsShowChange(e, item) {
            this.$emit('columnsChange', { type: 'show', value: { element: item, show: e } });
        },
        columnsFixedChange(e, fixed) {
            this.$emit('columnsChange', { type: 'fixed', value: { element: e, fixed: fixed } });
        },
    },
};
</script>
