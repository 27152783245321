import { dayjs } from 'element-plus';
import XLSX from 'xlsx'

class Workbook {
    SheetNames = [];
    Sheets = {};
}

const generateArray = (table) => {
    const out = [];
    const rows = table.querySelectorAll('tr');
    const ranges = [];
    for (let R = 0; R < rows.length; ++R) {
        const outRow = [];
        const row = rows[R];
        const columns = row.querySelectorAll('td');
        for (let C = 0; C < columns.length; ++C) {
            const cell = columns[C];
            const colspanStr = cell.getAttribute('colspan');
            const rowspanStr = cell.getAttribute('rowspan');
            const classStr = cell.getAttribute('class');
            let colspan;
            let rowspan;
            let cellValue;
            if (colspanStr) {
                colspan = parseInt(colspanStr);
            }
            if (rowspanStr) {
                rowspan = parseInt(rowspanStr);
            }
            if (cell.textContent.indexOf('[') >= 0) {
                cell.textContent.match(/\[(.+)\]/g);
                cellValue = RegExp.$1;
            } else {
                cellValue = /^(-?\d+)(\.\d+)?$/.test(cell.textContent) && cell.textContent.indexOf('%') == -1 && classStr && classStr.indexOf('customerId') == -1 ? parseFloat(cell.innerText) : cell.innerText.trim();
            }
            ranges.forEach(function (range) {
                if (R >= range.s.r && R <= range.e.r && outRow.length >= range.s.c && outRow.length <= range.e.c) {
                    for (let i = 0; i <= range.e.c - range.s.c; ++i) outRow.push(null);
                }
            });
            if (rowspan || colspan) {
                rowspan = rowspan || 1;
                colspan = colspan || 1;
                ranges.push({
                    s: {
                        r: R,
                        c: outRow.length,
                    },
                    e: {
                        r: R + rowspan - 1,
                        c: outRow.length + colspan - 1,
                    },
                });
            }
            outRow.push(cellValue !== '' ? cellValue : null);
            if (colspan) {
                for (let k = 0; k < colspan - 1; ++k) {
                    outRow.push(null);
                }
            }
        }
        out.push(outRow);
    }
    return [out, ranges];
};

const datenum = (date) => {
    return (+date - +new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
};

const sheetFromDataArray = (data) => {
    const ws = {};
    const range = {
        s: {
            c: 10000000,
            r: 10000000,
        },
        e: {
            c: 0,
            r: 0,
        },
    };
    for (let R = 0; R !== data.length; ++R) {
        for (let C = 0; C !== data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            const cell = {
                v: data[R][C],
                t: '',
                z: '',
            };
            if (cell.v == null) continue;
            const cellRef = XLSX.utils.encode_cell({
                c: C,
                r: R,
            });
            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n';
                cell.z = XLSX.SSF.get_table()[14];
                cell.v = datenum(cell.v);
            } else cell.t = 's';
            ws[cellRef] = cell;
        }
    }
    if (range.s.c < 10000000) {
        ws['!ref'] = XLSX.utils.encode_range(range);
    }
    return ws;
};

const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
};

const download = (blob, filname, cb) => {
    const elink = document.createElement('a');
    elink.download = filname;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    document.body.removeChild(elink);
    cb && cb();
};

export const exportExcel = (el, thead, filename, cb = () => {}) => {
    filename = filename + dayjs().format('YYYY/MM/DD/HH/mm');
    const oo = generateArray(el);
    const ranges = oo[1];
    const data = oo[0];
    const wsName = 'SheetJS';

    data[0] = thead;
    const wb = new Workbook();
    const ws = sheetFromDataArray(data);

    const result = thead.map((val) => {
        if (val == null) {
            return {
                wch: 20,
            };
        } else if (val.toString().charCodeAt(0) > 255) {
            return {
                wch: val.toString().length * 3,
            };
        } else {
            return {
                wch: val.toString().length * 2,
            };
        }
    });

    ws['!cols'] = result;

    ws['!merges'] = ranges;

    wb.SheetNames.push(wsName);
    wb.Sheets[wsName] = ws;

    const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary',
    });

    download(
      new Blob([s2ab(wbout)], {
          type: 'application/octet-stream',
      }),
      filename + '.xlsx',
      cb,
    );
};
