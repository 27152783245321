<template>
    <el-cascader :model-value='modelValue' :options='options' :props='propsOptions' filterable clearable
                 @change='changeHandle' :placeholder='placeholder' />
</template>

<script>
import { reqListAll } from '@/views/isv/isvInfo/api';
import { ElNotification } from 'element-plus';

function treeList(list) {
    list.forEach((item) => {
        if (item.ext?.children?.length) {
            item.children = item.ext.children;
            treeList(item.children);
        } else {
            item.children = [];
        }
    });
}

export default {
    name: 'isvSelect',
    props: ['modelValue', 'placeholder'],
    emits: ['update:modelValue', 'change'],
    data() {
        return {
            options: [],
        };
    },
    computed: {
        propsOptions() {
            return {
                value: 'isvNo',
                label: 'name',
                checkStrictly: true,
                emitPath: false,
            };
        },
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            reqListAll({ pageSize: 1000 }).then((res) => {
                console.log(res);
                if (res.code === 200) {
                    let list = res.data.records || [];
                    treeList(list);
                    this.options = list;
                } else {
                    ElNotification.error(res.msg);
                }
            });
        },
        changeHandle(e) {
            console.log(e);
            this.$emit('update:modelValue', e);
            this.$emit('change', e);
        },

        setValue(val = []) {
            let options = this.options;
            let res = [];
            val.forEach((v) => {
                let current = options.find((el) => el.label === v);
                if (current) {
                    res.push(current.value);
                    options = current.children;
                }
            });
            this.changeHandle(res);
        },
    },
};
</script>

<style scoped></style>
