<template>
    <div class="pagination-wrap">
        <div class="pagination-ops"><slot></slot></div>
        <el-pagination
            @size-change="onHandleSizeChange"
            @current-change="onHandleCurrentChange"
            class="mt15"
            :pager-count="5"
            :page-sizes="[10, 20, 50, 100]"
            background
            layout="total, sizes, prev, pager, next, jumper"
            v-bind="$attrs"
        ></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'pagination',
    emits: ['onHandleSizeChange', 'onHandleCurrentChange'],
    methods: {
        onHandleSizeChange(val) {
            this.$emit('onHandleSizeChange', val);
        },

        onHandleCurrentChange(val) {
            this.$emit('onHandleCurrentChange', val);
        },
    },
};
</script>

<style lang="scss" scoped>
.pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
