<template>
    <div class="new-input">
        <el-input :model-value="modelValue" :placeholder="realPlaceholder" @input="inputHandle" @blur="blurHandle" @focus="focusHandle" v-bind="$attrs">
            <template #prefix v-if="slots.prefix">
                <slot name="prefix"></slot>
            </template>

            <template #suffix v-if="slots.suffix">
                <slot name="suffix"></slot>
            </template>

            <template #prepend v-if="slots.prepend">
                <slot name="prepend"></slot>
            </template>

            <template #append v-if="slots.append">
                <slot name="append"></slot>
            </template>
        </el-input>

        <transition name="inputshow" mode="out-in">
            <span class="label" v-if="!realPlaceholder">{{ placeholder }}</span>
        </transition>
    </div>
</template>

<script setup>
import { ref, computed, useSlots } from 'vue';

const props = defineProps({
    // 输入框占位文本
    placeholder: {
        type: String,
        default: () => '请输入内容搜索图标或者选择图标',
    },
    modelValue: String,
});

const emit = defineEmits(['update:modelValue']);
const slots = useSlots();

// 是否获取焦点
const isFocus = ref(false);

// 输入框的占位文本
const realPlaceholder = computed(() => {
    return props.modelValue || isFocus.value ? '' : props.placeholder;
});

// 输入框事件
const inputHandle = (e) => {
    if (e) {
        e = e.trim();
    }
    emit('update:modelValue', e);
};

const blurHandle = () => {
    isFocus.value = false;
};

const focusHandle = () => {
    isFocus.value = true;
};
</script>

<style lang="scss">
.new-input {
    position: relative;
    width: 100%;

    .label {
        position: absolute;
        top: -10px;
        left: 6px;
        background-color: #fff;
        line-height: 1.2;
        padding: 0 10px;
        font-size: 14px;
        color: #409eff;

        &.hide {
            display: none;
        }

        &.show {
            display: block;
        }
    }
}

.inputshow-enter-active,
.inputshow-leave-active {
    will-change: transform;
    transition: all 0.3s ease;
}
.inputshow-enter-from,
.inputshow-leave-to {
    opacity: 0;
}
</style>
