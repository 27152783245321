import { createApp } from 'vue';
import pinia from '@/stores/index';
import App from './App.vue';
import router from './router';
import { directive } from '@/directive/index';
import other from '@/utils/other';
import ElementPlus from 'element-plus';
import ElementIcon from '@/global/icon';
import globalComponent from '@/global';
import 'element-plus/dist/index.css';
import '@/theme/index.scss';
import VueGridLayout from 'vue-grid-layout';
import LicensePlate from 'vue3-license-plate';
import 'vue3-license-plate/lib/licensePlate.css';

const app = createApp(App);

directive(app);
other.elSvg(app);

app.use(pinia).use(router).use(ElementPlus).use(ElementIcon).use(globalComponent).use(LicensePlate).use(VueGridLayout).mount('#app');
